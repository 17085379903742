export interface PriceDisplayProps {
    pricePrefix?:
        | string
        | { children: string; styles?: Record<string, string> };
    price?: string;
}

const getProductPriceDisplayProps = (
    pricing:
        | {
              Text?: string;
              Prefix?: string | null;
              ReplacedText?: string | null;
          }
        | null
        | undefined
): PriceDisplayProps => {
    if (!pricing || !pricing.Text) {
        return {};
    }

    return {
        pricePrefix: pricing.ReplacedText
            ? {
                  children: pricing.ReplacedText,
                  styles: {
                      textDecoration: 'line-through',
                  },
              }
            : pricing.Prefix || undefined,
        price: pricing.Text,
    };
};

export default getProductPriceDisplayProps;
